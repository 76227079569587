@import './assets/scss/normalize.css';
@import './assets/scss/layout';
@import './assets/scss/fonts';
@import url('https://use.typekit.net/gia4vas.css');

// @font-face {
//     font-family: 'mr-eaves-book';
//     src: url('./assets/fonts/mr-eaves-book.woff2') format('woff2'),
//         url('./assets/fonts/mr-eaves-book.woff2') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'mr-eaves-black';
//     src: url('./assets/fonts/mr-eaves-black.woff2') format('woff2'),
//         url('./assets/fonts/mr-eaves-black.woff2') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// $font-family-book: 'mr-eaves-book';
// $font-family-black: 'mr-eaves-black';

$zero: 0px;
$presetBorder: 1px;
$presetMargin: 10px;
$presetPadding: 10px;
$heroMargin: 30px;

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

body {
  @include margin($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
  @include padding($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
}

.frame {
  @include divDimensions(
    100%,
    100vh,
    $max-width: 100%,
    $max-height: auto,
    $min-width: null,
    $min-height: 600px
  ); //@include backgroundColor($primary, $opacity: 0.5);
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  @include position($position: fixed);
  z-index: 1;
  overflow-x: hidden;
  box-sizing: border-box;
  @include border(
    $border-radius: null,
    $border-style: null,
    $border-width: 0px,
    $border-color: null,
    $border-left-width: null,
    $border-top-width: null,
    $border-right-width: null,
    $border-bottom-width: null
  );
}

.menu-wrapper {
  @include divDimensions(
    100%,
    350px,
    $max-width: 100%,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  ); //@include backgroundColor($primary, $opacity: 0.5);
  overflow-x: hidden;
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $align-content: flex-end,
    $justify-content: flex-end,
    $flex-wrap: null
  );
  @include position($position: fixed);
  padding: 10px;
  bottom: 0px;
  z-index: 10;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  transition: all 0.5s;

  &.hide {
    transform: translateY(130px);
  }
}

.button-wrapper {
  @include divDimensions(
    100%,
    auto,
    $max-width: 100%,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  );
  @include flexContainer(
    $direction: row,
    $align-items: flex-end,
    $align-content: null,
    $justify-content: null,
    $flex-wrap: null
  );
}

.button-background {
  @include divDimensions(
    100%,
    150px,
    $max-width: 100%,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  );
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  @include backgroundColor(grey, $opacity: 1);
  @include margin($top: 0px, $right: 10px, $bottom: 0px, $left: 5px);
  color: white;
  @include border(
    $border-radius: null,
    $border-style: solid,
    $border-width: 0px,
    $border-color: grey,
    $border-left-width: null,
    $border-top-width: null,
    $border-right-width: null,
    $border-bottom-width: null
  );
  cursor: pointer;
  transition: all 1s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &.active {
    @include border(
      $border-radius: null,
      $border-style: solid,
      $border-width: 5px,
      $border-color: white,
      $border-left-width: null,
      $border-top-width: null,
      $border-right-width: null,
      $border-bottom-width: null
    );
  }
}

.overlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.arrow {
  @include divDimensions(
    30px,
    30px,
    $max-width: null,
    $max-height: null,
    $min-width: null,
    $min-height: null
  );
  @include margin($top: 5px, $right: 5px, $bottom: 5px, $left: 5px);
  cursor: pointer;
  transform: rotate(225deg);
  transition: all 0.5s;

  @include border(
    $border-radius: null,
    $border-style: solid,
    $border-width: null,
    $border-color: white,
    $border-left-width: 3px,
    $border-top-width: 3px,
    $border-right-width: 0px,
    $border-bottom-width: 0px
  );

  &.hide {
    transform: rotate(45deg);
  }
}

.text {
  z-index: 20;
  font-family: $font-family-book;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 15px;
}

p {
  color: white;
  font-family: $font-family-black;
  font-weight: 900;
  font-style: normal;
  text-transform: uppercase;
  font-size: 20px;
}

#viewer {
  width: 100vw;
  height: 50vh;
}

.logo {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('./assets/images/logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1000;
}

.text_view_nome {
  z-index: 10000;
}

.settings-button {
  @include divDimensions(
    25px,
    25px,
    $max-width: auto,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  ); //@include backgroundColor($primary, $opacity: 0.5);
  @include position($position: fixed, $top: 0px, $right: 0px);
  margin: 10px;
  z-index: 10;
  cursor: pointer;
  background-image: url('./assets/images/settings.svg');
}
.exit-button {
  @include divDimensions(
    25px,
    25px,
    $max-width: auto,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  ); //@include backgroundColor($primary, $opacity: 0.5);
  @include position($position: fixed, $top: 0px, $right: 0px);
  margin: 10px;
  z-index: 10;
  cursor: pointer;
  background-image: url('./assets/images/exit.svg');
}

.pairing-wrapper {
  @include divDimensions(
    100%,
    100%,
    $max-width: 100%,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  );
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  margin-top: 30px;

  & p {
    color: black;
  }
}

.pairing-button {
  @include divDimensions(
    250px,
    50px,
    $max-width: 100%,
    $max-height: auto,
    $min-width: null,
    $min-height: null
  );
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  @include backgroundColor(rgb(204, 204, 204), $opacity: 1);
  color: rgb(0, 0, 0);
  @include border(
    $border-radius: null,
    $border-style: solid,
    $border-width: 3px,
    $border-color: rgb(141, 141, 141),
    $border-left-width: null,
    $border-top-width: null,
    $border-right-width: null,
    $border-bottom-width: null
  );
  cursor: pointer;
  position: relative;
  margin: 10px;
}
